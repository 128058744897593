@import '../../styles/abstracts';

.modalWrap {
    z-index: 100;
	position: fixed;
    background: rgba($mono, 0.7);
    top: 0%;
    left: 0%;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .modalInner {
        z-index: 102;
        filter: none;
        text-align: center;
        background-color: white;
        padding: 26px 0px;
        width: 331px;
        max-width: calc(100vw - 60px);
		box-shadow: 0 0 10px 2px rgba($mono-5, 0.5);

        @media (min-width: $md) {
            width: 880px;
            max-width: 90vw;
            padding: 56px 0px;
            top: 45%;
        }

        .mainCta {
            font: normal normal normal pxToRem(21)/pxToRem(26) $roboto;
            margin-bottom: 25px;

            @media (min-width: $md) {
                font: normal normal normal pxToRem(40)/pxToRem(55) $roboto;
                margin-bottom: 35px; 
            }
        }

        .subText {
            font: normal normal normal pxToRem(15)/pxToRem(20) $roboto;
            
            @media (min-width: $md) {
                font: normal normal normal pxToRem(30)/pxToRem(55) $roboto;
            }
        }

        svg {
            height: 40px;
            width: auto;
            margin-bottom: 15px;
            
            @media (min-width: $md) {
                height: 50px;
                width: auto;
                margin-bottom: 25px;
            }
        }
    }
}