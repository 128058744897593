@import '../../styles/abstracts';


.outerIneligableContainer {
  .ineligableContainer {
    position: absolute;
    z-index: 100;
    -ms-overflow-style: none;
    scrollbar-width: thin;
    scrollbar-color: grey transparent;
    top: 0;
    left: 50%;
    margin: auto;
    transform: translate(-50%, -50%);
    min-width: calc(100vw - 16px);
    
  
    &:hover {
      -webkit-mask-position: left top;
    }
  
    height: fit-content;
    margin-top: 140px;


    @media(min-width: $md){
      margin-top: 120px;
    }
    
  
    .innerContainer {
      z-index: 100;
      width: 100%;
      height: fit-content;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      margin: auto;
  
      .modalWrap {
        border-radius: 2px;
        max-width: 480px;
        background-color: white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        
        .cta {
          z-index: 110;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          font: normal normal bold pxToRem(22)/pxToRem(28) $roboto;
          color: white;
          background-color: #083c74;
          padding: 12px 12px;
          
          @media(min-width: $md){
            font: normal normal bold pxToRem(32)/pxToRem(35) $roboto;
          }
        }
  
        .innerModalWrap {
          padding: 15px;
          @media(min-width: $md){
            padding: 23px;
          }
    
          .borderedContentWrap {
            position: relative;
            border-radius: 2px;
            border: 1px solid black;
            font: normal normal normal pxToRem(16)/pxToRem(22) $roboto;
            padding: 52px 15px 15px 15px;
  
            @media(min-width: $md){
              padding: 62px 20px 20px 20px;
            }
  
            .secondaryCta{
              p:last-child {
                margin-bottom: 24px;
              }
            }
  
            .buttonWrap {
              padding: 0px 16px;
  
              button.submitButton {
                padding: 12px 12px;
                font: normal normal 500 pxToRem(18)/pxToRem(20) $roboto;
                background-color: $uranus;
                color: white;
                border-radius: 5px;
                border: none;
                cursor: pointer;
                width: 100%;
  
                @media(min-width: $md){
                  font: normal normal 500 pxToRem(24)/pxToRem(24) $roboto;
                  padding: 12px 12px;
                }
  
              }

              button.exitButton {
                cursor: pointer;
                position: absolute;
                top: 0px;
                right: 10px;
                padding: 6px;
                border: none;
                font: normal normal 500 pxToRem(18)/pxToRem(20) $roboto;
                @media(min-width: $md){
                  font: normal normal 500 pxToRem(24)/pxToRem(24) $roboto;
                }
              }
            }
  
  
            .bundlesWrap {
              display: grid;
              grid-template-columns: 1fr 1.5fr;
              column-gap: 20px;
              row-gap: 20px;
              padding: 0px 8px;
              padding-bottom: 20px;
  
              .imgWrap {
                width: fit-content;
                margin: auto;
                img {
                  max-height: 92px;
                  min-height: 82px;
                  object-fit: cover;
                  width: auto;
                  flex-grow: 1;
                }
              }
  
              .ctaWrap {
                display: flex;
                flex-direction: column;
                justify-content: center;
                
                p {
                  display: inline-block;
                  margin-bottom: 6px;
                  font: normal normal normal pxToRem(16)/pxToRem(18) $roboto;
                  
                  @media(min-width: $md){
                    font: normal normal normal pxToRem(16)/pxToRem(18) $roboto;
                  }
                }
                .uppercase {
                  text-transform:uppercase;
                  margin-bottom: 2px;
                }
              }
            }
          }
        }
      }
    }
  }
}
