.bonuses--wrapper{

    .hero {
      &__h1 {
        font-size: 1.5rem;
        @include responsive(font-size, 1.25rem ,xs , sm, md,1.5rem, xl);
        margin: 0;
      }

      &__h2{
        @include responsive(font-size, 1.5rem, xs ,sm,2rem,2.5rem,3rem);
        margin: 0;
      }
    }//.hero

    .numbered-panels {
      margin-left: auto;
      margin-right: auto;
      @include responsive(margin-top, 2em, xs, sm, md, lg, 3em);
      @include responsive(margin-bottom, 1em, xs, sm, md, lg, 3em);

      p {
        font-size: 1.125em;
        margin-bottom: 1rem;
        line-height: 1.3;

        &:last-child {
          margin-bottom: 0;
        }

        &.alt-20px {
          font-size: 1.25rem;
        }
      }

      a {
        font-weight: 600;
        display: inline-block;

        @media (max-width: $xs) {
          padding-top: .5em;
        }
      }

      &__img {
        border: solid 4px #CBCED1;
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 339px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;
        @include responsive(height, 45vw, xs, 12rem, auto, lg, xl);

        &.promo-item-slot {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $white;
        }

        @media (min-width: $xs) {
          &.promo-item-slot {
            padding-top: 60%;
          }

          &-position {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        &:not(.first){
          @media (max-width: $sm) {
            margin-top: 3em;
          }
        }

        &.border-none {
          border: none;
          @include responsive(margin-bottom, 1.5em, xs, sm, 1em, lg, .5em);

          @media (max-width: $xs) {
            height: auto;
          }
        }
      }

      .panels {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .number {
        position: relative;
        background-color: #B88E33;
        font-family: $font-title;
        font-weight: $bold;
        @include responsive(margin-top, -1em, xs, sm, md, -.8em, xl);
        @include responsive(margin-bottom, .25, xs, sm, .25em, lg, .5em);
        margin-left: auto !important;
        margin-right: auto !important;
        border-radius: 50%;
        height: 2.5625rem;
        line-height: 1;
        width: 2.5625rem;
        text-align: center;
        color: #121212;
        @include responsive(font-size, 1.5rem, xs, sm, 1.125rem, lg, xl);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media (max-width: $xs) {
          height: 2.5625rem;
          width: 2.5625rem;
        }
      }
    }

    $weight: 900;
    @while $weight > 0 {
      .fw-#{$weight} { font-weight: 1 * $weight; }
      $weight: $weight - 100;
    }
  }
