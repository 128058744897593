@keyframes spinning {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}
.spinning {
	animation: spinning 2s linear infinite;
}
