@import '../../styles/abstracts.scss';

$bonusesDrawerOffset: 36px;

.supportLink {
	padding: 0 32px;

	@media (min-width: 1220px) {
		padding: 0 calc((100% - 1140px) / 2)
	}
}

.bonusDrawerOffset {
	margin-top: calc(75px + #{$bonusesDrawerOffset});
}

.supportLink {
	position: absolute;
	right: 0;
	font-size: pxToRem(14);
	line-height: pxToRem(14);

	&.bonusDrawerOffset {
		a {
			top: calc(84px + #{$bonusesDrawerOffset});
		}
	}

	a {
		color: $mono-4;
		position: relative;
		top: 84px;
		text-decoration: none;

		&:hover,
		&:focus,
		&:active {
			color: #93a5ae;
		}

	}

	&.light {
		a {
			color: $mono-3;

			&:hover,
			&:focus,
			&:active {
				color: $white;
			}
		}
	}
}

.header {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $loki;
	height: 56px;
	padding: 16px;
	color: $mono-3;
	font-family: $roboto;
	position: relative;
	z-index: 99;

	.container {
		width: 100%;
		max-width: 1140px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.usccaLogo {
			display: block;
			width: 40px;
			color: white;

			svg {
				display: block;
				width: 100%;
			}
		}

		.deltaDefenseLogo {
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;
			height: 32px;

			svg {
				height: 100%;
			}
		}
	}

	.tablet {
		display: none;
	}

	@media (min-width: $md) {
		height: 64px;

		.tablet {
			display: block;
		}

		.container {
			.usccaLogo {
				width: 45px;
			}

			.deltaDefenseLogo {
				height: 36px;

				em {
					font-size: pxToRem(10);
					line-height: pxToRem(10);
					margin-right: 8px;
					position: relative;
					top: 2px;
				}
			}
		}
	}
}
