@import '../../styles/abstracts';
@import '../../../node_modules/@delta-defense/universal-components/common/styles/components/btns';

.main {
	flex-grow: 1;
	text-align: center;
	padding: 30px;
}

.message {
	font-size: pxToRem(20);
}

.questions,
.back {
	font-size: pxToRem(16);
}

.reloadCta {
	padding: 20px;

	a {
		@extend .btn;
		@extend .btn-primary;
		text-transform: capitalize;
		text-decoration: none;
	}
}
