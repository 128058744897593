@mixin sharedBackgroundContainer {
    background-image: url('/img/email-background/mobile-tablet.jpg');
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    min-height: calc(80vh - 88px);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $black;
    max-width: 100vw;
    background-color: black;

    @media (min-width: $md) {
        min-height: calc(100vh - 88px);

        .content {
            max-width: 375px;
        }
    }

    @media (min-width: $lg) {
        background-image: url('/img/email-background/desktop.jpg');
        background-size: auto 100%;
    }
}
