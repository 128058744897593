@import '../../styles/abstracts.scss';

.fullWidth {
  width: 100%;
}

.backLinkHeader,
.backLinkMain {
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;
  }
}

.backLinkHeader {
  font-size: pxToRem(12);
  padding: 0 32px;
  margin-top: 84px;
  color: $uranus;
  position: absolute;

  @media (min-width: 1220px) {
    padding: 0 calc((100% - 1140px) / 2)
  }
}

.backLinkMain {
  font-size: pxToRem(14);
  color: $mono4;
  margin-top: 8px;

  svg path {
    fill: $mono4;
  }

  &:hover,
  &:active,
  &:focus {
    color: $uranus;

    svg path {
      fill: $uranus;
    }
  }
}
