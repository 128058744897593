@import '../../node_modules/@delta-defense/universal-components/common/styles/abstracts/abstracts';
@import '../../node_modules/@delta-defense/universal-components/common/styles/components/btns';
@import '../../node_modules/@delta-defense/universal-components/common/styles/deltadefense/variable-overrides';
@import './mixins.scss';

$bolder: 700;
$medium: 500;

$success: #28a745;
$mars: #9c231f;
$ares: #b61d32;
$money: #008000;
$loki: #0b0b0b;
$neptune: #003a70;
$uranus: #0074e0;
$uranusHover: #0458a7;
$monoThreePointFive: #9ea1a3;
$orangeCream: #f28519;
$moon: #386cc6;
$moonFarside: #2f5ca8;
$mono4: #787c7f;
$pluto: #3c4e6d;
$plutoHover: #2b3950;
$gold: #b88e33;
$gold-1: #b98d32;
$platinum: #747678;
$elite: #003a70;

$stratum: 'Stratum', Verdana, sans-serif;
$roboto: 'Roboto', sans-serif;

$cdn: 'https://cdn.usconcealedcarry.com';

$featureFlagEnabledMobileCTAButtonHeight: 105px;
