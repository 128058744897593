@import '../../styles/abstracts';

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba($mono, 0.7);
	z-index: 100;
}

.modal {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: block;
	width: 95%;
	padding: pxToRem(24) pxToRem(16);
	border-radius: 5px;
	background-image: linear-gradient(#fff, $mono-1);
	color: $mono-5;
	font-weight: $bold;
	text-align: center;
	z-index: 101;
	box-shadow: 0 0 10px 2px rgba($mono-5, 0.5);

	@media screen and (min-width: $md) {
		width: pxToRem(600);
	}
}

.heading {
	border-bottom: 1px solid $prime;
	width: 95%;
	margin: 0 auto;
	padding-bottom: pxToRem(5);
	margin-bottom: pxToRem(16);
	font-size: pxToRem(24);

	@media screen and (min-width: $lg) {
		font-size: pxToRem(28);
		margin-bottom: pxToRem(22);
	}
}

.body {
	font-size: pxToRem(16);
	font-weight: 400 !important;

	@media screen and (min-width: $lg) {
		font-size: pxToRem(18);
	}
}

.button {
	box-sizing: inherit;
	overflow: visible;
	white-space: nowrap;
	touch-action: manipulation;
	line-height: 1.1;
	text-transform: capitalize;
	border: 1px solid transparent;
	font-size: 1.25rem;
	font-weight: 700;
	padding: 0.6875rem 1.75rem 0.75rem;
	user-select: none;
	-webkit-user-select: none;
	border-radius: 5px;
	letter-spacing: 0.05em;
	cursor: pointer;
	min-width: 15.625rem;
	max-width: 95%;
	font-family: $font-body;
	background: #0074e0;
	color: $white  !important;
	margin: 20px auto 0 auto;
}

.back {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	min-height: 30px;
	font-size: pxToRem(16);
	line-height: pxToRem(26);
}
