@import './abstracts.scss';

@font-face {
	font-family: 'Stratum';
	src: url('#{$cdn}/fonts/Stratum/Stratum1WebBold/Stratum1WebBlack.woff2') format('woff2'),
		url('#{$cdn}/fonts/Stratum/Stratum1WebBold/Stratum1WebBlack.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: block;
}
