@import '../../styles/abstracts.scss';

.container {
  display: block;
  position: absolute;
  top: 56px;
  background: $mono-1 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 10px #00000029;
  z-index: 98;
  width: 100%;
  outline: none;

  @media (min-width: $md) {
    top: 64px;
    width: 310px;
    font-size: 0.75rem;
    right: 20px;
    transition: width .05s;

    &.narrow {
      width: 245px;
    }
  }

  @media (min-width: 1220px) {
    right: calc((100% - 1140px) / 2)
  }

  button {
    background: transparent linear-gradient(180deg, #CDB88A 0%, #BAA880 100%) 0% 0% no-repeat padding-box;
    height: 36px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    cursor: pointer;
    border: 0;
    transition: width .5s;

    @media (min-width: $md) {
      border-radius: 0 0 5px 5px;
      justify-content: flex-start;
    }

    .cartIconWrapper {
      display: flex;
      position: absolute;
      right: 0px;

      @media (min-width: $md) {}

      svg {
        height: 20px;
        width: 28px;
      }

      span {
        height: 15px;
        width: 15px;
        background-color: $mono-1;
        border-radius: 8px;
        font-size: 0.875rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        right: 7px;
        bottom: 3px;

        &.hidden {
          background-color: transparent;
        }
      }
    }
  }

  ul {
    overflow: hidden;
    list-style: none;
    margin: 0;
    padding: 0 16px;
    margin-top: calc(-100vh - 150px);
    transition: margin-top .75s;
    overflow: auto;
    max-height: 450px;

    li {
      display: flex;
      align-items: center;
      border-bottom: 1px solid $mono-3;
      padding: 15px 0;

      b,
      strong {
        text-transform: uppercase;
      }

      &:last-child {
        border-bottom: none;
      }

      img,
      svg {
        width: 80px;
        margin-right: 24px;
      }

      svg {
        height: 64px;
        padding: 0px 12px;
        margin-right: 25px;
      }
    }
  }

  .cartTextChevronWrapper {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    line-height: 1;

    &.surprise {
      margin-left: -10px;

      @media (min-width: $md) {
        margin-left: -20px;
      }
    }

    @media (min-width: $md) {
      margin-left: -10px;
    }

    span {
      font-size: 0.875rem;

      @media (min-width: $sm) {
        font-size: 0.75rem;
      }

      &.smallerText {
        font-size: 0.8125rem;

        .hideBelowThreeSixty {
          font-size: 0.8125rem;

          @media (max-width: 359px) {
            display: none;
          }
        }

        @media (min-width: $sm) {
          font-size: 0.6575rem;

          .hideBelowThreeSixty {
            font-size: 0.6575rem;
          }
        }
      }
    }
  }

  .togglableIcon {
    margin-left: 4px;
    margin-right: 8px;
    position: relative;
    bottom: 1px;

    .up {
      display: none;
    }

    @media (min-width: $md) {
      bottom: 0px;

      svg {
        width: 13px;
      }
    }
  }

  &:focus-within {
    @media (min-width: $md) {
      width: 350px;
    }

    ul {
      margin-top: 0;
    }

    .togglableIcon {
      .down {
        display: none;
      }

      .up {
        display: block;
      }
    }
  }
}
