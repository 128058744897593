@import '../../styles/abstracts.scss';

.subHeader {
	color: $mono-5;
}

.stepContainer {
	form {
		width: calc(100% - 30px) !important;

		h1 {
			padding-bottom: 25px;
		}

		header {
			width: 100%;
		}
	}
}

.newPaymentMethodHeader {
	text-transform: none;
	font-weight: 500;
	text-align: left;
	width: 100%;
	margin-bottom: 32px;
	font-size: pxToRem(24);
}

.cardOnFile {
	background-color: $mono-1;
	padding: 30px 20px 20px;
	width: 100%;
	margin-bottom: 32px;

	.useCardOnFilePrompt {
		font-size: pxToRem(18);
		margin-top: 30px;
		font-weight: $medium;
	}

	@media (min-width: $md) {
		padding: 30px;
	}

	p {
		font-weight: $bold;
		line-height: 22px;
	}

	label {
		font-size: pxToRem(18);
		max-width: 210px;

		@media (min-width: 375px) {
			max-width: 270px;
		}

		@media (min-width: $md) {
			max-width: 400px;
		}
	}

	.info {
		display: flex;
		justify-content: center;
		margin-bottom: 24px;
		height: 44px;

		.brand {
			height: 100%;
			margin-right: 20px;

			img {
				height: 50px !important;
			}

			svg {
				height: 100%;
				width: auto;
			}
		}

		.numberDate {
			p {
				font-size: pxToRem(16);
				margin: 0;
			}
		}
	}
}

.stepContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 0 !important;
	height: 100%;

	.banners {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	@media (min-width: $md) {
		form {
			max-width: 450px !important;
			display: flex;
			flex-direction: column;
			align-items: center;

			button {
				margin-top: 30px;
			}
		}
	}

	input[type=checkbox] {
		border-color: $mars !important;
	}

	label {
		p {
			word-spacing: -0.9px;
		}
	}
}

.activationNotice {
	margin-top: 16px;
}

.textSmall {
	font-size: pxToRem(14);
	line-height: pxToRem(18);
}
