@import '../../styles/abstracts.scss';

$heightWithoutStickyButton: 168px;
$copyRightsSpacer: 24px;

.footer {
	display: flex;
	justify-content: center;
	background-color: $loki;
	padding: 16px;
	color: $mono-3;
	font-family: $roboto;
	z-index: 51;

	.spacerForStickyBottomButton {
		height: $featureFlagEnabledMobileCTAButtonHeight;
		width: 100%;
		@media (min-width: $md){
			display: none;
		}
	}

	.container {
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: 1140px;

		.copyRights {
			font-size: pxToRem(11);
			line-height: pxToRem(16);
			margin-top: $copyRightsSpacer;
			margin-bottom: calc($copyRightsSpacer / 2);
			text-align: center;
	
			@media (min-width: $md) {
				text-align: left;
				max-width: 800px;
				margin: 0 auto;
			}

			a {
				color: $white;
			}
	
			hr {
				border-top: 0;
				border-bottom: 1px solid $mono-4;
				margin: 16px auto;
				min-width: 260px;
				max-width: 80%;
	
				@media (min-width: $md) {
					max-width: 100%;
					margin: 16px 0;
				}
			}

			.logoAndPhone {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 10px;
		
				@media (min-width: $md) {
					flex-direction: row;
					align-items: flex-end;
					justify-content: space-between;
				}
		
				img {
					display: block;
					max-height: 32px;
					width: 90px;
		
					@media (min-width: $md) {
						max-height: 46px;
						width: 130px;
					}
				}
		
				p {
					margin: 0;
					line-height: 0;
		
					a {
						color: $mono-3;
						font-size: pxToRem(14);
						line-height: pxToRem(16);
						letter-spacing: 1px;
						text-decoration: none;
		
						@media (min-width: $md) {
							font-size: pxToRem(16);
						}
		
						&:hover,
						&:focus,
						&:active {
							color: #93a5ae;
						}
					}
				}
			}

			.terms {
				
				@media (min-width: $md) {
					padding-right: 50px;
				}

				.mobile {

					@media (min-width: $md) {
						display: none;
					}
				}
			
				.tablet {
					display: none;
			
					@media (min-width: $md) {
						display: block;
					}
				}
			}
		}
	}
}
