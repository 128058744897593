@import './abstracts';
@import '../../node_modules/@delta-defense/universal-components/common/styles/vendor/bs-lite';
@import '../../node_modules/@delta-defense/universal-components/common/styles/base/resets';
@import '../../node_modules/@delta-defense/universal-components/common/styles/base/typography';
@import '../../node_modules/@delta-defense/universal-components/common/styles/base/utilities';
@import '../../node_modules/@delta-defense/address-validation-service/components/useValidatedAddressModal/styles.css';
@import '../../node_modules/@delta-defense/address-validation-service/components/addressPredictionField/styles.css';
@import '../../node_modules/@delta-defense/react-modal/styles.css';
@import '../../node_modules/@delta-defense/technical-difficulties-modal/components/technicalDifficultiesModal/styles.css';
@import './checkoutexitmodal';
@import './fonts.scss';

body {
	height: 100vh; // allow us to pin footer to bottom of screen if we're not filling the screen
}

#__next {
	display: flex;
	flex-direction: column;

	header {
		flex-grow: 0;
	}

	footer {
		flex-grow: 0;
	}
}

h1,
.h1 {
	font-family: $font-title;
	font-weight: $normal;
	text-transform: none;
	@include font-sizes(2rem, xs, sm, md, 2.75rem, xl);
}

h2,
.h2 {
	font-family: $font-title;
	font-weight: $normal;
	text-transform: uppercase;
	@include font-sizes(1.625rem, xs, sm, md, 2rem, xl);
}

h3,
.h3 {
	font-family: $font-title;
	font-weight: $normal;
	text-transform: none;
	@include font-sizes(1.625rem, xs, sm, md, 2rem, xl);
}

h4,
.h4 {
	font-family: $font-title;
	font-weight: $normal;
	text-transform: none;
	@include font-sizes(1.375rem, xs, sm, md, 1.625rem, xl);
}

h5,
.h5 {
	font-family: $font-body;
	font-weight: $normal;
	text-transform: uppercase;
	@include font-sizes(1.25rem, xs, sm, md, 1.5rem, xl);
}

h6,
.h6 {
	font-family: $font-body;
	font-weight: $normal;
	text-transform: none;
	@include font-sizes(1.125rem, xs, sm, md, 1.25rem, xl);
}

body {
	padding-top: pxToRem(125);
}

@media (min-width: 576px) {
	body {
		padding-top: pxToRem(125);
	}
}

@media (min-width: 992px) {
	body {
		padding-top: pxToRem(125);
	}
}

body {
	&.blank {
		padding: 0;
	}

	#zuora_payment {
		container-type: inline-size;

		// Override hot-loaded zuora styles
		#z_hppm_iframe {
			background-color: transparent !important;
			border: 0 !important;
			width: 100% !important;
			height: 678px !important;

			@container (min-width: 418px) {
				height: 501px !important;
			}
		}
	}

	padding-top: 0;
	word-spacing: 0;
}

// Grey out readonly inputs
input:read-only {
	background-color: $mono-2;
}

/* Chrome, Safari, Edge, Opera */
// remove arrow up and down from number input
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
	display: none;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

// Modal Overlay
.popup-overlay {
	background-color: $mono;
	opacity: 0.5;
}

technical-difficulties-modal {
	div[class|='background'],
	div[class|='container'] {
		z-index: 1000;

		a {
			text-decoration: underline;
		}
	}
}

.compensatedStepContainer,
.stepContainer {
	min-height: calc(80vh - 88px);
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 50px 0;

	@media (min-width: $md) {
		padding: 75px 0;
	}

	form,
	.standardXPadding {
		width: calc(100% - 60px);
		max-width: 364px;
	}
}

.linkButton {
	margin: 0;
	padding: 0;
	border: none;
	display: inline;
	color: $uranus;
	font-size: inherit;
	font-weight: 500;

	&:hover {
		color: $uranusHover;
		text-decoration: underline;
		cursor: pointer;
	}
}

button[type='button'],
input[type='radio'],
input[type='checkbox'] {
	cursor: pointer;
}

use-validated-address-modal div[class^='container'] {
	z-index: 99;
}
