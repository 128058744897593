@import '../../styles/abstracts.scss';

.contentWrapperWithBackground,
.contentWrapper {
	width: 100%;
	flex-grow: 1;
	min-height: 50vh;

	@media (min-width: $md) {
		min-height: 80vh;
	}
}

.contentWrapperWithBackground {
	@include sharedBackgroundContainer;
}
